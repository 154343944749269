import React, { useState } from 'react';
import axios from 'axios';

const login = async ({ email, password }) => {
  await axios.post(
    'http://localhost:8080/jwtToken',
    {
      email,
      pass: password,
    },
    {
      withCredentials: true,
    },
  );
};

const refreshAuth = async () => {
  await axios.post(
    'http://localhost:8080/refreshAuth',
    {},
    {
      withCredentials: true,
    },
  );
};

const LoginForm = () => {
  const [loginInfo, setLoginInfo] = useState({ email: '', password: '' });

  const updatePassword = value =>
    setLoginInfo(prev => ({ ...prev, password: value }));
  const updateEmail = value =>
    setLoginInfo(prev => ({ ...prev, email: value }));

  return (
    <div>
      <label>
        Email
        <input
          onChange={e => updateEmail(e.target.value)}
          value={loginInfo.email}
          type="email"
        ></input>
      </label>
      <label>
        Password
        <input
          onChange={e => updatePassword(e.target.value)}
          value={loginInfo.password}
          type="password"
        ></input>
      </label>
      <button type="button" onClick={() => login(loginInfo)}>
        Login
      </button>
      <button onClick={() => refreshAuth()}>Refresh Token</button>
    </div>
  );
};

export default LoginForm;
