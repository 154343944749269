import React, { useState } from 'react';
import axios from 'axios';
import LoginForm from './LoginForm';

axios.interceptors.response.use(
  response => {
    console.log(response);
    return response;
  },
  error => {
    console.log(error);
    if (error.response && error.response.status === 401) {
    } else {
      return Promise.reject(error);
    }
  },
);
const addFood = async foodItemName => {
  try {
    await axios.post(
      'http://localhost:8080/food',
      {
        displayName: foodItemName,
      },
      {
        withCredentials: true,
      },
    );
  } catch (error) {
    console.error(error);
  }
};

const TrackFoodItem = () => {
  const [foodItemName, setFoodItemName] = useState('');
  const [shouldShowLoginForm, setShouldShowLoginForm] = useState(true);

  return (
    <div>
      {shouldShowLoginForm && <LoginForm></LoginForm>}
      <input onChange={e => setFoodItemName(e.target.value)}></input>
      <button type="button" onClick={() => addFood(foodItemName)}>
        Click Me!
      </button>
    </div>
  );
};

export default TrackFoodItem;
